
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IBoard, IUser } from '@/utils/types'
import { CLEAR_BOARD, LOAD_BOARD, LOAD_FONTS } from '@/store/actions'
import { boardBackground } from '@/utils/helpers'
import BoardFormModal from '@/components/board/BoardFormModal.vue'
import BoardBackgroundSelect from '@/components/board/BoardBackgroundSelect.vue'
import BoardFontSelect from '@/components/board/BoardFontSelect.vue'
import BoardViewItems from '@/components/board/BoardViewItems.vue'
import BoardItemFormModal from '@/components/board/BoardItemFormModal.vue'
import Loading from '@/components/common/Loading.vue'
import { RollbackOutlined } from '@ant-design/icons-vue'

@Options({
  components: {
    BoardFormModal,
    BoardItemFormModal,
    BoardFontSelect,
    BoardBackgroundSelect,
    BoardViewItems,
    RollbackOutlined,
    Loading,
  },
  directives: { maska },
})
export default class BoardView extends Vue {
  boardBackground = boardBackground

  get loading() {
    return this.$store.state.board.loading
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get board(): IBoard {
    return this.$store.state.board.item
  }

  get id() {
    return this.$route.params.id
  }

  goHome() {
    this.$router.push({
      name: 'gives',
    })
  }

  beforeUnmount() {
    this.$store.dispatch(CLEAR_BOARD)
  }

  async getBoard() {
    await this.$store.dispatch(LOAD_BOARD, this.id)
  }

  async getFontsList() {
    await this.$store.dispatch(LOAD_FONTS)
  }

  async created() {
    await this.getFontsList()

    if (this.id) {
      await this.getBoard()
    }
  }
}
