<template>
  <Loading class="min-h-screen" :loading="loading" />
  <div v-if="!loading && board?.id" class="relative">
    <div class="absolute z-10 p-2 -right-0">
      <a-tooltip placement="leftTop">
        <template #title>
          <span>Back to home</span>
        </template>
        <a-button ghost shape="circle" @click="goHome">
          <template #icon><RollbackOutlined /></template>
        </a-button>
      </a-tooltip>
    </div>
    <!-- Board heading -->
    <div class="board-heading py-10 px-4 bg-fixed" :style="{ background: board.titleBg }">
      <!-- Board title -->
      <div class="mt-1 mb-1" :style="{ 'font-family': board.fontFamily }">
        <h1 class="text-3xl text-center">
          {{ board.title }}
        </h1>
      </div>
    </div>
    <!-- Board items -->
    <div
      class="board-container"
      :style="[
        {
          'font-family': board.fontFamily,
        },
        boardBackground(board.boardBg),
      ]"
      :class="[board.boardBg.includes('http') ? 'bg-fixed bg-center bg-cover bg-no-repeat' : '']"
    >
      <BoardViewItems v-if="board.id" :board="board" />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IBoard, IUser } from '@/utils/types'
import { CLEAR_BOARD, LOAD_BOARD, LOAD_FONTS } from '@/store/actions'
import { boardBackground } from '@/utils/helpers'
import BoardFormModal from '@/components/board/BoardFormModal.vue'
import BoardBackgroundSelect from '@/components/board/BoardBackgroundSelect.vue'
import BoardFontSelect from '@/components/board/BoardFontSelect.vue'
import BoardViewItems from '@/components/board/BoardViewItems.vue'
import BoardItemFormModal from '@/components/board/BoardItemFormModal.vue'
import Loading from '@/components/common/Loading.vue'
import { RollbackOutlined } from '@ant-design/icons-vue'

@Options({
  components: {
    BoardFormModal,
    BoardItemFormModal,
    BoardFontSelect,
    BoardBackgroundSelect,
    BoardViewItems,
    RollbackOutlined,
    Loading,
  },
  directives: { maska },
})
export default class BoardView extends Vue {
  boardBackground = boardBackground

  get loading() {
    return this.$store.state.board.loading
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get board(): IBoard {
    return this.$store.state.board.item
  }

  get id() {
    return this.$route.params.id
  }

  goHome() {
    this.$router.push({
      name: 'gives',
    })
  }

  beforeUnmount() {
    this.$store.dispatch(CLEAR_BOARD)
  }

  async getBoard() {
    await this.$store.dispatch(LOAD_BOARD, this.id)
  }

  async getFontsList() {
    await this.$store.dispatch(LOAD_FONTS)
  }

  async created() {
    await this.getFontsList()

    if (this.id) {
      await this.getBoard()
    }
  }
}
</script>
