<template>
  <div v-if="loading" class="flex justify-center items-center" :spinning="loading">
    <a-spin />
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {},
})
export default class Loading extends Vue {
  @Prop()
  loading!: boolean
}
</script>
